@import 'src/styles/vars';

.NoAccessPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
}

.NoAccessPage__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.NoAccessPage__message {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #102d69;
  margin-top: 40px;

  & > a {
    @include text_caption_large_bold;
    color: #6b7a99;
    font-weight: 600;
    text-decoration: underline;
  }
}
