@import 'src/styles/vars';

.ProjectsPage__header {
  margin-bottom: $spacing-small;

  @include media-down(tablet-large) {
    margin-bottom: $spacing-medium-x;
  }
}

.DocumentsPage__spinner {
  display: block;
  margin: $spacing-large auto 0;
}

.DocumentsPage__section_title {
  margin-top: $spacing-medium !important;
  margin-bottom: $spacing-small-x !important;
}

.ProjectsPage_content {
  max-width: 800px;
}

.DocumentsPage__document {
  overflow: hidden;
  text-overflow: ellipsis;
}

.DocumentsPage__sourcelink {
  white-space: nowrap;
}
