@import 'src/styles/vars';

.Header__logo {
  cursor: pointer;
}

.Header__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: $spacing-small-3x;
}

.Header__divider {
  height: 16px;
  background-color: #ebedf2;
}
