@import 'src/styles/vars';

.QuizQuestions__header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $sizing-32;
  gap: $spacing-small-3x;
}

.QuizQuestions__headerPages {
  @include text_caption_large_bold;
}

.QuizQuestions__questions {
  margin: $spacing-small 0;
}
