@import 'src/styles/vars';

.Input {
  &:hover {
    border-color: $on-surface-brand !important;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &:global(.hse-Input_focus) {
    border-color: $on-surface-brand !important;
  }
}
