@import 'src/styles/vars';

$messages-width: 375px;

.MoodleMessages {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  right: -$messages-width;
  top: 0;
  z-index: $z-index-max;
  height: 100%;
  visibility: hidden;
  width: $messages-width;
  transition: right 0.2s ease-in-out, visibility 0.2s ease-in-out;
  box-shadow: 0 1px 12px 0 rgb(106 122 152 / 12%);
  background-color: #fff;

  @include media-down(tablet-large) {
    width: 100%;
    right: -100%;
  }
}

.MoodleMessages_open {
  visibility: visible;
  right: 0;
}

.MoodleMessages__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 30px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}

.MoodleMessages__heading {
  @include text_caption_x_large_bold;
}

.MoodleMessages__moodleContainer {
  width: 100%;
  height: 100%;
  padding-bottom: $spacing-small-x;
}

.MoodleMessages__moodle {
  width: 100%;
  height: 100%;
}

.MoodleMessages__moodle_loading {
  visibility: hidden;
  opacity: 0;
  top: -100%;
}
