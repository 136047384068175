@import 'src/styles/vars';

.GradeTable {
  border-collapse: separate;
  border-spacing: 0 $spacing-small-3x;
  text-align: left;
  margin-top: $spacing-medium;

  caption {
    @include text_caption_x_large_bold;
    color: $text-primary-black;
    text-align: left;
    margin-bottom: $spacing-small;
  }

  tr {
    background-color: $on-surface-light-gray;
  }

  thead,
  thead tr {
    background-color: transparent;
  }

  th,
  td {
    @include text_caption_small_regular;
    padding: 4px 16px;
    border: none;
    text-align: left;
  }

  td {
    color: $text-primary-black;
  }

  th {
    color: $text-secondary;
  }

  @include media-down(tablet-small) {
    width: 100%;

    caption {
      @include text_caption_large_bold;
      color: $text-primary-black;
    }

    tr {
      background: transparent;
    }

    th,
    td {
      padding: 4px 0;
    }

    th {
      @include text_caption_small_bold;
      color: $text-primary-black;
      border-bottom: solid 1px $divider-light-gray;
    }

    tr:not(:first-child) th {
      padding-top: 16px;
    }

    td {
      @include text_caption_small_regular;
    }

    td.GradeTable__text_header {
      color: $text-secondary;
    }
  }
}

td.GradeTable__text_right {
  text-align: right;
}

td.GradeTable__text_nowrap {
  white-space: nowrap;
}

td.GradeTable__text_bold {
  @include text_caption_small_bold;
}
