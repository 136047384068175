@import 'src/styles/vars';

.GradesPage__header {
  margin-bottom: $spacing-small;

  @include media-down(tablet-large) {
    margin-bottom: $spacing-medium-x;
  }
}

.GradesPage__spinner {
  display: block;
  margin: $spacing-large auto 0;
}
