@import 'src/styles/vars';

.UserLayout {
  display: grid;
  grid-template:
    'header header' auto
    'sidebar content' 1fr
    / min-content 1fr;
}

.UserLayout__header {
  position: fixed;
  background-color: $background-white;
  grid-area: header;
  z-index: $z-index-header;
}

.UserLayout__sidebar {
  position: fixed;
  top: $header-height;

  @include media-down(tablet-small) {
    top: $header-height-small;
  }
}

.UserLayout__content {
  min-height: 100vh;
  min-width: 0;
  padding-top: $header-height;
  display: flex;
  flex-direction: column;

  @include media-down(tablet-small) {
    padding-top: $header-height-small;
  }
}

.UserLayout__outlet {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.UserLayout__footer {
  grid-area: footer;
}

.UserLayoutState {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
