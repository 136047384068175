@import 'src/styles/vars';

$transition-time-sidebar: 0.3s;

$transition-base: max-width $transition-time-sidebar ease-out, transform $transition-time-sidebar ease-out,
  box-shadow $transition-time-sidebar ease-out;

.Sidebar__wrapper {
  display: flex;
}

.Sidebar__stub {
  height: 100%;
  width: 72px;

  @include media-down('tablet-large') {
    width: 0;
  }
}

.Sidebar__container {
  position: absolute;
  height: calc(100% - #{$header-height});
  top: $header-height;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: $transition-base;
  background: $on-surface-light-gray;
  max-width: 72px;
  z-index: 100;

  &_open {
    max-width: 260px;
    box-shadow: 0 4px 24px rgb(106 122 152 / 15%);
  }

  @include media-down('tablet-large') {
    height: calc(100% - #{$header-height-small});
    top: $header-height-small;
    max-width: 90vw;

    &:not(&_open) {
      transform: translateX(-100%);
    }
  }
}

.Sidebar__paneControlContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.Sidebar__paneControlIcon {
  transition: transform $transition-quick;
  transform: rotate(0);

  &_open {
    transform: rotate(-180deg);
  }
}

.Sidebar__paneMenuWrapper {
  position: relative;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  border-radius: $radius-small;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Sidebar__paneMenuItem {
  margin-top: $spacing-small-3x;
}
