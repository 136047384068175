@import '../../../../styles/vars';

.UserGreeting__greeting {
  color: #6b7a99;
}

.UserGreeting__avatar {
  border-radius: 50%;
}

@media (max-width: 1279px) {
  .UserGreeting {
    display: flex;
  }

  .UserGreeting__avatar {
    width: 56px;
    height: 56px;
    margin-right: 16px;
  }

  .UserGreeting__greeting {
    @include text_caption_medium_regular;
    margin-bottom: 4px;
  }

  .UserGreeting__name {
    @include text_heading_h4;
  }
}

@media (min-width: 1280px) {
  .UserGreeting {
    text-align: center;
  }

  .UserGreeting__avatar {
    width: 88px;
    height: 88px;
    margin-bottom: 16px;
  }

  .UserGreeting__greeting {
    @include text_paragraph_large;
    margin-bottom: 8px;
  }

  .UserGreeting__name {
    @include text_heading_h3;
  }
}
