@import 'src/styles/vars';

.Activity {
  flex: 1 0 0;
  max-width: 535px;
}

.Activity__header {
  @include text_caption_large_bold;
}

.Activity__headerDivider {
  margin-top: $spacing-small-4x;
}

.Activity__cards {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-small-3x;
  gap: $spacing-small-3x;
}

/* stylelint-disable-next-line selector-class-pattern */
.Activity_variant_aside {
  padding: $spacing-small;
  background: $background-white;
  border: 1px solid $divider-light-gray;
  border-radius: $radius-large-x;

  .Activity__header {
    @include text_caption_medium_bold;
  }

  .Activity__cards {
    margin-top: $spacing-small;
    gap: $spacing-small-x;
  }
}
