@import 'src/styles/vars';

.Badge {
  @include text_label_x_small_bold;
  display: inline-flex;
  vertical-align: top;
  padding: 6px 8px;
  border-radius: $radius-small;
}

.Badge_primary {
  background: $brand-5;
  color: $text-dark-brand;
}
