@import '../vars';
/* stylelint-disable selector-class-pattern */

.hse-BaseCheckboxIcon {
  border-color: $divider-dark;
}

.hse-BaseCheckboxIcon_checked {
  background-color: $brand-1;
}

.hse-Checkbox:hover .hse-Checkbox__icon {
  border-color: $brand-1;
}

.hse-Checkbox_checked:hover .hse-Checkbox__icon:not(.hse-BaseCheckboxIcon_radio) {
  background-color: $brand-twilight;
}

.hse-BaseCheckboxIcon_focused {
  box-shadow: 0 0 0 2px $brand-5;
}

.hse-BaseCheckboxIcon_checked.hse-BaseCheckboxIcon_focused {
  box-shadow: 0 0 0 2px $brand-5;
  background-color: $brand-twilight;
}
