@import 'src/styles/vars';

.Breadcrumbs {
  @include text_caption_medium_regular;
}

.Breadcrumbs__link {
  color: $accent-blue-1;
}

.Breadcrumbs__divider {
  display: inline-flex;
  margin: 0 4px;
}
