@import 'src/styles/vars';

.CalendarNav {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  align-content: center;
  gap: $spacing-small-x;
}

.CalendarNav__left,
.CalendarNav__right {
  display: flex;
  align-items: center;
}

.CalendarNav__right {
  justify-content: flex-end;
}

.CalendarNav__title {
  @include text_caption_x_large_bold;
  text-transform: capitalize;
  text-align: center;
}

.CalendarNav__button {
  display: inline-flex;
  vertical-align: top;
  text-transform: capitalize;
  user-select: none;
}

@include media-down(tablet-small) {
  .CalendarNav__title,
  .CalendarNav__button {
    @include text_caption_small_bold;
  }
}
