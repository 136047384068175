@use 'sass:math';
@import 'src/styles/vars';

$days-amount: 7;
$event-indicator-size: 8px;

@mixin event-indicator {
  width: $event-indicator-size;
  height: $event-indicator-size;
  background: $icon-brand;
  border-radius: 50%;
}

.Nav {
  margin-bottom: 20px;
}

.Grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat($days-amount, minmax(0, 1fr));
}

.Grid__col {
  padding: 9px;
  border: 1px solid $divider-gray;
  margin-left: -1px;

  &:nth-child(#{$days-amount}n + 1) {
    margin-left: 0;
  }
  &:nth-child(n + #{$days-amount + 1}) {
    margin-top: -1px;
  }
}

.Grid__day {
  @include text_caption_medium_bold;
  text-transform: capitalize;
}

.Grid__date {
  min-height: 124px;
}

.MonthCalendarDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  cursor: pointer;
  transition: background $transition-quick;

  &:hover {
    background: $on-surface-light-brand;
  }
}

.MonthCalendarDate__dayNum {
  @include text_caption_x_small_regular;
  position: relative;
  display: inline-flex;
  vertical-align: top;

  span {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: $radius-small;
  }
}

.MonthCalendarDate_weekend {
  .MonthCalendarDate__dayNum {
    color: $text-secondary;
  }
}

.MonthCalendarDate_otherMonth {
  .MonthCalendarDate__dayNum {
    color: $text-tertiary;
  }
}

.MonthCalendarDate_selected {
  .MonthCalendarDate__dayNum::after {
    background: $brand-5;
  }
}

.MonthCalendarDate__eventsIndicator {
  @include event-indicator;
  position: absolute;
  z-index: 1;
  bottom: -5px;
  left: 50%;
  margin-left: math.div($event-indicator-size, -2);
}

.MonthCalendarDate__events {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.MonthCalendarDate__event {
  padding: 3px 0;
}

.MonthCalendarEvent {
  display: flex;
  align-items: center;
  gap: 2px;
  transition: $transition-quick;
  cursor: pointer;

  &:hover {
    color: $text-brand;
  }
}

.MonthCalendarEvent__indicator {
  @include event-indicator;
  flex-shrink: 0;
}

.MonthCalendarEvent__indicator_multiDay {
  background: $icon-primary;
}

.MonthCalendarEvent__name {
  @include text_label_x_small_regular;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MonthCalendarDate__showMore {
  @include text_label_x_small_bold;
  padding-left: $event-indicator-size + 2px;
  margin-top: 3px;
  color: $text-brand;
}

.Loader {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color-background-white, 0.7);
}

.EventModal {
  width: 800px;
}

.EventModalEvent {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-x;
}

.EventModalEvent__name {
  @include text_caption_x_large_bold;
}

.EventModalEvent__description {
  @include text_caption_small_regular;
  color: $text-secondary;
}

.EventModalEvent__date {
  @include text_caption_small_regular;
  text-transform: capitalize;
}

@include media-down(tablet-small) {
  .MonthCalendar {
    padding: $spacing-small-x;
    border: 1px solid $divider-gray;
    border-radius: 5px;
  }

  .Nav {
    margin-bottom: $spacing-small-2x;
  }

  .Grid {
    gap: $spacing-small-x 5px;
  }

  .Grid__col {
    padding: 0;
    margin: 0;
    border: none;
  }

  .Grid__day {
    @include text_label_x_small_bold;
    text-align: center;
  }

  .Grid__date {
    min-height: 22px;
    text-align: center;
  }

  .MonthCalendarDate {
    align-items: center;
  }

  .MonthCalendarDate__dayNum {
    @include text_label_x_small_regular;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;

    &::after {
      width: 100%;
      height: 100%;
    }
  }

  .EventModalEvent__name {
    @include text_caption_medium_bold;
  }
}
