@import 'src/styles/vars';

.SidebarItem__button {
  display: flex;
  justify-content: flex-start;
  width: 100% !important;
  white-space: nowrap;
  border-radius: $radius-medium;
  padding: $spacing-small-2x;
  background-color: $on-surface-light-brand;

  &::before {
    border-radius: $radius-medium;
  }
}

.SidebarItem__button_content {
  display: flex;
  overflow: hidden;
  justify-content: flex-start !important;
  color: $text-secondary !important;
}

.SidebarItem__button_active,
.SidebarItem__button:hover {
  .SidebarItem__button_content {
    color: $text-dark-brand !important;
  }

  &::before {
    background-color: $on-surface-medium-brand;
  }
}
