@import '../vars';
/* stylelint-disable selector-class-pattern */

.hse-Input {
  &:hover {
    border-color: $brand-1;
  }
}

.hse-Input_focus {
  border-color: $brand-1;
}
