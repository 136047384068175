@import 'src/styles/vars';

@mixin unset {
  all: unset;
}

.Footer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 $spacing-large $spacing-small-x;
  background: $on-surface-light-gray;
}

.Footer__colContainer {
  display: flex;
  margin-top: $spacing-small-x;
}

.Footer__container_mobile {
  flex-direction: column;

  .Footer__colContainer {
    flex-direction: column;
  }
}

.Footer__col {
  display: flex;
  flex-direction: column;
}

.Footer__colTitle {
  @include text_caption_small_bold;
  color: $text-secondary;
  margin-bottom: $spacing-small-3x;
}

.Footer__colItems {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-3x $spacing-small-x;
}

.Footer__colItem {
  @include unset;
  @include text_caption_small_regular;
  color: $text-secondary;
}

a.Footer__colItem {
  cursor: pointer;
  transition: color $transition-quick;

  &:hover,
  &:focus,
  &:focus-visible {
    color: $brand-twilight;
  }
}

.Footer__colItemSpan {
  white-space: nowrap;
}
