@import 'src/styles/vars';

.LoginGreeting__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

$media: 'screen and (max-width: $breakpoint-tablet-small)';

.LoginGreeting__content_container {
  display: flex;
  align-items: flex-start;

  @include media-down(tablet-small) {
    align-items: center;
    flex-direction: column;
  }
}

.LoginGreeting__logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing-medium;

  @include media-down(tablet-small) {
    margin-right: 0;
    margin-bottom: $spacing-medium;
  }
}

.LoginGreeting__greeting_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 445px;

  @include media-down(tablet-small) {
    align-items: center;
  }
}

.LoginGreeting__greeting_p,
.LoginGreeting__greeting_h3 {
  @include media-down(tablet-small) {
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.LoginGreeting__greeting_p {
  color: $accent-blue-dark;
}

.LoginGreeting__greeting_h3 {
  color: $accent-blue-dark;
}

.LoginGreeting__link {
  color: $text-secondary;
}

.LoginGreeting__error {
  color: $color-system-error-dark;
}
