@import '../vars';
/* stylelint-disable selector-class-pattern */

.hse-Button_variant_primary {
  background-color: $brand-1;

  .hse-Button__content {
    color: $text-primary-white;
  }

  &:hover {
    &::before {
      background-color: $brand-twilight;
    }
  }

  &.focus-visible:not(.hse-Button_hasSpinner, .hse-Button_inactive, .hse-Button_disabled),
  &.hse-Button_focused:not(.hse-Button_hasSpinner, .hse-Button_inactive, .hse-Button_disabled) {
    box-shadow: 0 0 0 2px $brand-2;

    &::before {
      background-color: $brand-twilight;
    }
  }

  &.hse-Button_inactive,
  &.hse-Button_disabled {
    .hse-Button__content {
      color: rgb(255 255 255 / 60%);
    }

    &::before {
      background: $brand-3;
    }
  }

  &:not(.hse-Button_spinner_done) .hse-Button__spinner {
    --spinner-color: #{$spinner-brand-1};
    --spinner-color-hover: #{$spinner-brand-1};
    color: $brand-1;
  }

  &.hse-Button_spinner_done {
    background-color: $brand-1;
  }
}

.hse-Button_variant_secondary {
  border-color: $brand-3;

  /* stylelint-disable-next-line */
  .hse-Button__content {
    color: $brand-1;

    &:hover {
      color: $brand-1;
    }
  }

  &:hover {
    border-color: $brand-1;
  }

  .hse-Button__bubble {
    background: rgba($brand-1, $opacity-10);
  }

  &.hse-Button_custom-color {
    border-color: currentcolor;

    &,
    .hse-Button__content {
      color: currentcolor;
    }
  }

  /* stylelint-disable-next-line */
  &.focus-visible:not(.hse-Button_hasSpinner, .hse-Button_inactive, .hse-Button_disabled),
  &.hse-Button_focused:not(.hse-Button_hasSpinner, .hse-Button_inactive, .hse-Button_disabled) {
    color: $brand-1;
    border-color: $brand-1;
    box-shadow: 0 0 0 2px $brand-5;
  }

  &.hse-Button_inactive,
  &.hse-Button_disabled {
    border-color: $on-surface-gray;

    .hse-Button__content {
      color: $text-tertiary;
    }
  }

  &:not(.hse-Button_spinner_done) .hse-Button__spinner {
    --spinner-color: #{$spinner-brand-1};
    --spinner-color-hover: #{$spinner-brand-1};
    color: $brand-1;
  }

  &.hse-Button_spinner_done {
    background-color: $brand-1;
  }
}

.hse-Button_variant_tertiary {
  color: $brand-1;

  &:hover::before {
    background-color: $on-surface-gray;
  }

  &:hover {
    background-color: transparent;
  }

  .hse-Button__bubble {
    background: rgba($brand-1, $opacity-10);
  }

  /* stylelint-disable-next-line */
  &.focus-visible:not(.hse-Button_hasSpinner, .hse-Button_inactive, .hse-Button_disabled),
  &.hse-Button_focused:not(.hse-Button_hasSpinner, .hse-Button_inactive, .hse-Button_disabled) {
    box-shadow: 0 0 0 2px $brand-2;
  }

  &.hse-Button_inactive,
  &.hse-Button_disabled {
    .hse-Button__content {
      color: $text-quaternary;
    }
  }

  &:not(.hse-Button_spinner_done) .hse-Button__spinner {
    --spinner-color: #{$spinner-brand-1};
    --spinner-color-hover: #{$spinner-brand-1};
    color: $brand-1;
  }

  &.hse-Button_spinner_done {
    background-color: $brand-1;
  }
}
