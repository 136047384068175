@import 'src/styles/vars';

.ActivityCard {
  display: flex;
  align-items: center;
  gap: $spacing-small-x;
  padding: $spacing-small-x;
  transition: $transition-quick;
  transition-property: box-shadow;
  background-color: $color-background-white;
  border-radius: $radius-large;

  /* stylelint-disable-next-line selector-class-pattern */
  &:not(.ActivityCard_variant_aside):hover {
    box-shadow: #{$shadow-hover};
    z-index: 10;
  }

  @include media-down(tablet-large) {
    padding-right: $spacing-small-2x;
  }
}

.ActivityCard_inactive {
  pointer-events: none;
}

.ActivityCard__state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: $sizing-64;
  height: $sizing-64;
  border-radius: $radius-large;
  background: $on-surface-medium-brand url('./icons/check.svg') center center no-repeat;
}

.ActivityCard__state_play {
  background: url('./icons/play.svg') center center no-repeat, $accent-gradient-diagonal;
}

.ActivityCard__state_close {
  background-color: $on-surface-light-brand;
  background-image: url('./icons/close.svg');
}

.ActivityCard__state_quiz {
  background-image: none;
}

.ActivityCard__stateIcon {
  width: $sizing-40;
  height: $sizing-40;
  color: $brand-2;
}

.ActivityCard__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-small-3x;
}

.ActivityCard__title {
  @include text_caption_small_bold;
  color: $text-secondary;
}

.ActivityCard__iconButton {
  background: $brand-6 !important;
  pointer-events: none;
}

.ActivityCard__iconButtonIcon {
  color: $icon-brand;
}

/* stylelint-disable-next-line selector-class-pattern */
.ActivityCard_variant_aside {
  padding: 0;
  gap: $spacing-small-3x;
  align-items: flex-start;

  .ActivityCard__state {
    width: 28px;
    height: 28px;
    border-radius: $radius-small;
    background-size: 16px 12px;
  }

  .ActivityCard__state_play {
    background: $on-surface-medium-brand url('./icons/play_aside.svg') center center no-repeat;
    background-size: 12px 14px;
  }

  .ActivityCard__state_close {
    background-size: 14px 19px;
  }

  .ActivityCard__title {
    padding-top: $spacing-small-4x;
    color: $text-brand;
  }
}
