@import 'src/styles/vars';

.DisciplineCards {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  &:not(&_withPlaceholder) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: $spacing-medium-x;

    @include media-down(tablet-small) {
      gap: $spacing-small-3x;
    }
  }
}

.DisciplineCards__card {
  display: flex;
  min-width: 100% !important;
}

.DisciplineCards__placeholder {
  display: flex;
  width: 100%;
  padding-right: $spacing-small-x;
  padding-left: $spacing-small-x;
  color: $text-secondary;
}

.DisciplineCards__loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}
