@import 'src/styles/vars';

.DisciplinePage__container {
  position: relative;
  width: 100%;
}

.DisciplinePage__container_centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.DisciplinePage__headerContainer {
  margin-bottom: $spacing-large;

  @include media-down(tablet-large) {
    margin-bottom: $spacing-medium-x;
  }
}

.DisciplinePage__header {
  &:not(:last-child) {
    margin-bottom: $spacing-small-x;

    @include media-down(tablet-large) {
      margin-bottom: $spacing-small-3x;
    }
  }
}

.DisciplinePage__description {
  color: $text-secondary;

  @include media-down(tablet-large) {
    @include text_caption_medium_regular;
  }
}

.DisciplinePage__bc {
  margin-top: $spacing-small-2x;
}
