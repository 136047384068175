@import 'src/styles/vars';

.PageHeader__container {
  display: flex;
  width: 100%;
}

.PageHeader__contentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &_mobile {
    flex-direction: column;
    justify-content: initial;
  }
}

/* ------ content_left ------ */
.PageHeader__contentLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* ------ button ------ */
.PageHeader__buttonBackIcon {
  color: $icon-secondary;
}

/* ------ title ------ */
.PageHeader__title {
  width: 100%;

  span {
    display: block;
  }

  @include media-down(tablet-large) {
    span {
      @include text_caption_x_large_bold;
    }
  }
}

/* ------ link ------ */
.PageHeader__link {
  @include text_caption_small_bold;
  color: $text-secondary;
}

/* ------ input ------ */
.PageHeader__input {
  &_stretch {
    width: 100%;
  }
}

.PageHeader__inputIcon {
  * {
    color: $icon-primary;
  }
}
