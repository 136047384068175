@import 'src/styles/vars';

.MoodleContent {
  @include text_caption_large_regular;

  b,
  strong {
    @include text_caption_large_bold;
  }

  h1,
  h2,
  h3,
  h4 {
    @include text_caption_x-large_bold;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  a {
    border-bottom: 1px solid $brand-1;
  }

  *:not(:last-child) {
    margin-bottom: $spacing-small-3x;
  }

  .MoodleContent__iFrameContainer {
    margin: 0;

    & > div {
      position: relative;
      height: 0;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .MoodleContent__iFrameContainer:not(:last-child),
  img:not(:last-child),
    // ...<p><img/></p>...
  *:not(:last-child) img:only-child {
    margin-bottom: $spacing-small-x;
  }

  /* stylelint-disable-next-line */
  .MoodleContent__iFrameContainer:not(:first-child),
  img:not(:first-child),
    // ...<p><img/></p>...
  *:not(:first-child) img:only-child {
    margin-top: $spacing-small-x;
  }

  ul,
  ol {
    padding-left: $spacing-small;
    margin-top: 0;
  }

  ul li {
    list-style: circle;
  }
}

.MoodleContent_small {
  @include text_caption_small_regular;

  b,
  strong {
    @include text_caption_small_bold;
  }
}

.MoodleContent_medium {
  @include text_caption_medium_regular;

  b,
  strong {
    @include text_caption_medium_bold;
  }
}
