/* === Colors === */

$accent-green-dark: #096237;
$accent-green-1: #228b45;
$accent-green-2: #00b86e;
$accent-green-light: #e9f9ef;
$accent-red-dark: #b9212e;
$accent-red-1: #d82e2e;
$accent-red-2: #f0522b;
$accent-red-light: #ffeeed;
$accent-orange-dark: #8f5714;
$accent-orange-1: #cd8703;
$accent-orange-2: #ff8000;
$accent-orange-light: #fef8ec;
$accent-blue-dark: #102d69;
$accent-blue-1: #0050cf;
$accent-blue-2: #2076ff;
$accent-blue-light: #f0f5ff;
$accent-purple-dark: #5c275e;
$accent-purple-1: #733176;
$accent-purple-2: #b455b8;
$accent-purple-light: #f9f1f9;
$accent-gradient-horizontal: linear-gradient(360deg, #102d69 0.17%, #930784 100%);
$accent-gradient-diagonal: linear-gradient(45deg, #102d69 0%, #930784 100%);

/* --- background --- */
$background-white: #fff;
$background-light-gray: #f7f9fa;

/* --- brand --- */
$brand-dark: #312642;
$brand-twilight: #513f6d;
$brand-1: #75529d;
$brand-2: #947fb5;
$brand-3: #c1b5d4;
$brand-5: #dcd5e7;
$brand-6: #eeebf3;

/* --- divider --- */
$divider-dark: #a0aabd;
$divider-gray: #e1e4eb;
$divider-light-gray: #ebedf2;

/* --- icon --- */
$icon-brand: #75529d;
$icon-light-brand: #c1b5d4;
$icon-primary: #6b7a99;
$icon-secondary: #a0aabd;
$icon-tertiary: #c0c6d3;
$icon-quaternary: #d3d7e0;
$icon-white: #fff;

/* --- on-surface --- */
$on-surface-white: #fff;
$on-surface-black: #0f0f14;
$on-surface-gray: #ebedf2;
$on-surface-light-gray: #f7f9fa;
$on-surface-brand: #715898;
$on-surface-medium-brand: #c1b5d4;
$on-surface-light-brand: #eeebf3;
$on-surface-dark-gray: #6b7a99;
$on-surface-medium-gray: #a0aabd;

/* --- overlay --- */
$overlay-black-40: rgb(15 15 20 / 40%);
$overlay-white-10: rgb(255 255 255 / 10%);

/* --- surface --- */
$surface-white: #fff;

/* --- text --- */
$text-primary-white: #fff;
$text-primary-black: #0f0f14;
$text-secondary: #6b7a99;
$text-tertiary: #a0aabd;
$text-quaternary: #c0c6d3;
$text-brand: #75529d;
$text-dark-brand: #513f6d;

/* --- Spinners colors --- */
$spinner-brand-1: $brand-1;

/* $- border-radius $- */
$radius-large-x: 16px;

/* === spacing === */
$spacing-small-4x: 4px;
