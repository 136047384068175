@import 'src/styles/vars';

.DisciplineTabs__container {
  display: flex;
  width: 100%;
}

$color-active: $brand-1;

@mixin tab-active {
  color: $color-active !important;

  &::after {
    background: $color-active !important;
  }
}

.DisciplineTabs__tabsItem {
  color: $text-secondary;

  &::after {
    background: transparent;
  }

  /* stylelint-disable selector-class-pattern */
  &:hover,
  &:active,
  &:focus,
  &:global(.hse-TabsItem_selected) {
    /* stylelint-enable selector-class-pattern */
    @include tab-active;
  }

  @include media-down(tablet-small) {
    font-size: $font-size-14;
  }
}

.DisciplineTabs__tabsItem_active {
  @include tab-active;
}
