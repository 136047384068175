@import 'src/styles/vars';

tr.QuizTableCard_disciplineRow {
  background-color: $on-surface-gray;

  td {
    padding: $spacing-small-3x $spacing-small-x;
  }
}

tr.QuizTableCard_quizRow {
  position: relative;
  background-color: $on-surface-light-brand;

  td {
    padding: $spacing-small-3x $spacing-small-x 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: calc(100% - #{$spacing-small-x});
    height: 8px;
    bottom: -8px;
    left: $spacing-small-x;
    background-color: $on-surface-light-brand;
  }
}

tr.QuizTableCard_attemptRow {
  background-color: $on-surface-light-gray;

  td {
    width: 25%;
    padding: 8px 4px;
  }
}

tr.QuizTableCard_quizRow td:first-child {
  padding-left: $spacing-small-x * 2;

  &::before {
    width: $spacing-small-x;
  }
}

tr.QuizTableCard_attemptRow td:first-child {
  padding-left: $spacing-small-x * 2;

  &::before {
    width: $spacing-small-x;
  }
}

tr.QuizTableCard_quizHeadRow {
  background-color: $on-surface-light-brand;

  &:not(:first-child) th {
    @include text_caption_x_small_regular;
    color: $text-secondary;
    padding: 0 4px 8px;
    width: 25%;
  }

  th:first-child {
    padding-left: $spacing-small-x * 2;

    &::before {
      width: $spacing-small-x;
    }
  }
}

.QuizTableCard_quizHeadRowValue {
  @include text_caption_small_regular;
  margin-top: 4px;
  color: $text-primary-black;
}

tr.QuizTableCard_quizRow,
tr.QuizTableCard_attemptRow,
tr.QuizTableCard_quizHeadRow {
  td:first-child,
  th:first-child {
    position: relative;
    padding-right: 0;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $surface-white;
    }
  }
}

.QuizTableCard__chevron {
  transform: rotate(90deg);
  cursor: pointer;
}

.QuizTableCard__chevron_open {
  transform: rotate(-90deg);
}

.QuizTableCard__spinner {
  vertical-align: sub;
  margin-left: 4px;
}
