@import '../vars';
/* stylelint-disable selector-class-pattern */

.hse-IconButton.hse-IconButton_variant_default {
  background-color: $brand-1;

  .hse-IconButton__content {
    color: $color-base-white;
  }

  &:hover {
    &::before {
      background-color: $brand-twilight;
    }
  }

  &.hse-IconButton_focused:not(.hse-IconButton_spinner, .hse-IconButton_inactive, .hse-IconButton_disabled) {
    box-shadow: 0 0 0 2px $brand-3;

    &::before {
      background-color: $brand-twilight;
    }
  }

  &.hse-IconButton_inactive,
  &.hse-IconButton_disabled {
    .hse-IconButton__content {
      color: $icon-tertiary;
    }

    &::before {
      background: $brand-6;
    }
  }

  &:not(.hse-IconButton_spinner_done) .hse-IconButton__spinner {
    --spinner-color: #{$spinner-brand-1};
    --spinner-color-hover: #{$spinner-brand-1};
    color: $brand-1;
  }

  &.hse-IconButton_spinner_done {
    background-color: $brand-1;
  }
}

.hse-IconButton.hse-IconButton_variant_ghost {
  /* stylelint-disable-next-line */
  .hse-IconButton__content {
    color: $icon-secondary;
  }

  &:hover {
    background-color: $on-surface-gray !important;

    .hse-IconButton__content {
      color: $icon-primary !important;
    }
  }

  &.hse-IconButton_focused:not(.hse-IconButton_spinner, .hse-IconButton_inactive, .hse-IconButton_disabled) {
    box-shadow: 0 0 0 2px $brand-5;

    .hse-IconButton__content {
      color: $icon-primary;
    }
  }

  &.hse-IconButton_inactive,
  &.hse-IconButton_disabled {
    border-color: transparent;

    /* stylelint-disable-next-line */
    .hse-IconButton__content {
      color: $icon-tertiary;
    }
  }

  &:not(.hse-IconButton_spinner_done) .hse-IconButton__spinner {
    --spinner-color: #{$spinner-brand-1};
    --spinner-color-hover: #{$spinner-brand-1};
    color: $brand-1;
  }

  &.hse-IconButton_spinner_done {
    background-color: $brand-1;
  }
}

.hse-IconButton.hse-IconButton_variant_ghost.hse-IconButton_surface_dark {
  /* stylelint-disable-next-line */
  .hse-IconButton__content {
    color: $icon-white;
  }

  &:hover {
    background-color: $on-surface-brand !important;

    .hse-IconButton__content {
      color: $icon-white !important;
    }
  }

  &.hse-IconButton_focused:not(.hse-IconButton_spinner, .hse-IconButton_inactive, .hse-IconButton_disabled) {
    box-shadow: 0 0 0 1px $brand-5;

    .hse-IconButton__content {
      color: $icon-white;
    }
  }

  &.hse-IconButton_inactive,
  &.hse-IconButton_disabled {
    border-color: transparent;

    /* stylelint-disable-next-line */
    .hse-IconButton__content {
      color: $icon-primary;
    }
  }

  &:not(.hse-IconButton_spinner_done) .hse-IconButton__spinner {
    --spinner-color: #{$brand-1};
    --spinner-color-hover: #{$brand-1};
    color: $brand-1;
  }

  &.hse-IconButton_spinner_done {
    background-color: $brand-1;
  }
}
