@import 'src/styles/vars';

.QuizCard {
  position: relative;
  border: 1px solid $divider-gray;
  border-radius: $radius-large;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: $spacing-small-2x;
    background: $accent-gradient-diagonal;
    border-radius: $radius-large;
  }
}

.QuizCard__title {
  @include text_caption_large_bold;
  position: relative;
  padding: $spacing-small-x;
  min-height: $spacing-large-x;
  text-align: center;
  color: $text-primary-white;
}

.QuizCard__content {
  position: relative;
  padding: $spacing-medium;
  background: $on-surface-white;
  border-radius: $radius-large;

  @include media-down(tablet-large) {
    padding: $spacing-small-x;
  }
}
