@import 'src/styles/vars';

.QuizAttemptRow {
  padding: 4px 16px;
  background-color: $on-surface-light-gray;
  display: grid;
  grid-template-columns: 160px 160px 1fr;
  align-items: center;
  column-gap: $spacing-medium;
  width: 100%;
}

.QuizAttemptRow_head {
  background-color: transparent;
}

.QuizAttemptRow__item {
  @include text_caption_small_regular;
}

.QuizAttemptRow__item_head {
  @include text_caption_small_regular;
  color: $text-secondary;
}
