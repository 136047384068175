.DecorMain__container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_vertical {
    flex-direction: row;
  }
}

.DecorMain__row {
  display: flex;
  flex-direction: row;

  &_vertical {
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
  }
}

.DecorMain__cell {
  flex: 1;
}
