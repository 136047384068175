@import '../../../../styles/vars';

.UpcomingDisciplines__heading {
  @include text_caption_medium_bold;
  padding: 6px 0;
  border-bottom: 1px solid #e1e4eb;
  margin-bottom: 16px;
}

.UpcomingDisciplines__discipline {
  background-color: #eeebf3;
  border-radius: 12px;
  padding-right: 16px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.UpcomingDisciplines__disciplineTitle {
  @include text_caption_small_bold;
  @include line-clamp(2);
  color: #6b7a99;
}

.UpcomingDisciplines__lockIconContainer {
  width: 64px;
  height: 64px;
  background-color: #c1b5d4;
  flex-shrink: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
