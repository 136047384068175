@import 'src/styles/vars';

.QuizAttemptCard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  row-gap: 4px;
}

.QuizAttemptCard__head {
  @include text_caption_small_bold;
  grid-column: 1 / -1;
}

.QuizAttemptCard__divider {
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 4px;
}

.QuizAttemptCard__title {
  @include text_caption_small_regular;
  color: $text-secondary;
}

.QuizAttemptCard__value {
  @include text_caption_small_regular;
}
