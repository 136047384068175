@import 'src/styles/vars';

.QuizCompletionState__content {
  &:not(:last-child) {
    margin-bottom: $spacing-medium-x;
  }
}

.QuizCompletionState__text {
  @include text_caption_x_large_regular;

  &:not(:last-child) {
    margin-bottom: $spacing-small;
  }

  @include media-down(tablet-large) {
    @include text_caption_large_regular;
  }
}

.QuizCompletionState__button {
  @include media-down(tablet-large) {
    width: 100%;
  }
}

.QuizCompletionState__review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.QuizCompletionState__buttonReview {
  &:not(:first-child) {
    margin-top: $spacing-small-3x;
  }
}
