@import 'src/styles/vars';

.QuizAttempts {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: $spacing-small-3x;
  align-items: center;

  @include media-down(tablet-large) {
    row-gap: 20px;
  }
}

.QuizAttempts__button {
  @include media-up(tablet-large) {
    margin-top: $spacing-small-3x;
  }
}

.QuizAttempts__buttonIcon {
  transform: rotate(90deg);
}

.QuizAttempts__buttonIcon_expanded {
  transform: rotate(-90deg);
}
