@import 'src/styles/vars';

.ActivitiesSection {
  &:not(:last-child) {
    margin-bottom: $spacing-medium;
  }
}

.ActivitiesSection__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid $divider-gray;
  margin-bottom: $spacing-medium;
  cursor: pointer;
}

.ActivitiesSection__icon_opened {
  transform: rotate(180deg);
}

.ActivitiesSection__title {
  @include text_caption_x_large_bold;
}
