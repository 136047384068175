@import 'src/styles/vars';

.Anchor {
  font-family: $font-family-proxima-nova;
  font-weight: $font-weight-600;

  &_primary {
    color: $text-secondary;
    border-bottom-color: $text-quaternary !important;

    &:hover {
      color: $brand-1 !important;
      border-bottom-color: transparent !important;
    }

    &:focus {
      color: $brand-1 !important;
      border-bottom-color: $brand-1 !important;
    }
  }

  &_secondary {
    color: $brand-1 !important;
    border: none !important;

    &:hover {
      color: $brand-twilight !important;
    }

    &:focus {
      color: $brand-twilight !important;
    }
  }

  &_large {
    @include text_caption_large_bold;
  }

  &_medium {
    @include text_caption_medium_bold;
  }

  &_small {
    @include text_caption_small_bold;
  }
}
