@import 'src/styles/vars';

tr.QuizTableRow_disciplineRow {
  background-color: $on-surface-gray;
}

tr.QuizTableRow_quizRow,
tr.QuizTableRow_attemptRow {
  td:first-child {
    position: relative;
    padding-right: 0;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $surface-white;
    }
  }
}

tr.QuizTableRow_quizRow {
  background-color: $on-surface-light-brand;

  td:first-child {
    padding-left: $spacing-small-x * 2;

    &::before {
      width: $spacing-small-x;
    }
  }
}

tr.QuizTableRow_attemptRow {
  background-color: $on-surface-light-gray;

  td:first-child {
    padding-left: $spacing-small-x * 3;

    &::before {
      width: $spacing-small-x * 2;
    }
  }
}

tr.QuizTableRow_total {
  background-color: $on-surface-light-brand;
}

.QuizTableRow__expandCell {
  cursor: pointer;
}

.QuizTableRow__chevron {
  @include media-down(tablet-small) {
    margin-left: $spacing-small-3x;
  }
}

.QuizTableRow__chevron_open {
  transform: rotate(90deg);
}

.QuizTableRow__spinner {
  vertical-align: sub;
  margin-left: 4px;
}
