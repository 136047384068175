@import 'src/styles/vars';

.CalendarCard {
  max-width: 800px;
  padding: $spacing-small;
  border: 1px solid $divider-gray;
  border-radius: 5px;
}

.CalendarCard__head {
  margin-bottom: $spacing-small-x;
}

.CalendarCard__viewSelect {
  min-width: 0;
  width: 100%;
  max-width: 220px;
}

@include media-down(tablet-small) {
  .CalendarCard {
    border: none;
    padding: 0;
  }

  .CalendarCard__viewSelect {
    max-width: 132px;
  }
}
