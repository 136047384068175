@import 'src/styles/vars';

$placeholder-size: 182px;

.Nav {
  margin-bottom: 20px;
}

.List {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $spacing-small-x;
}

.List__event {
  padding-bottom: 20px;
  border-bottom: 1px solid $divider-gray;

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.List__dataPlaceholder {
  @include text_caption_medium_regular;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $placeholder-size;
  border-radius: $radius-small;
  background: $on-surface-light-brand;
  color: $text-secondary;
}

.DayCalendarEvent {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-x;
}

.DayCalendarEvent__name {
  @include text_caption_x_large_bold;
}

.DayCalendarEvent__description {
  @include text_caption_small_regular;
  color: $text-secondary;
}

.DayCalendarEvent__date {
  @include text_caption_small_regular;
  text-transform: capitalize;
}

.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $placeholder-size;
  max-height: 100dvh;
}

@include media-down(tablet-small) {
  .Nav {
    margin-bottom: $spacing-small;
  }

  .DayCalendarEvent__name {
    @include text_caption_medium_bold;
  }
}
