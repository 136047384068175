/* breakpoints */

@mixin media-up($breakpoint) {
  @if $breakpoint == mobile-small {
    @media screen and (min-width: 320px) {
      @content;
    }
  } @else if $breakpoint == mobile-large {
    @media screen and (min-width: 375px) {
      @content;
    }
  } @else if $breakpoint == tablet-small {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == tablet-large {
    @media screen and (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == desktop-small {
    @media screen and (min-width: 1280px) {
      @content;
    }
  } @else if $breakpoint == desktop-medium {
    @media screen and (min-width: 1440px) {
      @content;
    }
  } @else if $breakpoint == desktop-large {
    @media screen and (min-width: 1920px) {
      @content;
    }
  }
}

@mixin media-down($breakpoint) {
  @if $breakpoint == mobile-small {
    @media screen and (max-width: 319px) {
      @content;
    }
  } @else if $breakpoint == mobile-large {
    @media screen and (max-width: 374px) {
      @content;
    }
  } @else if $breakpoint == tablet-small {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == tablet-large {
    @media screen and (max-width: 1023px) {
      @content;
    }
  } @else if $breakpoint == desktop-small {
    @media screen and (max-width: 1279px) {
      @content;
    }
  } @else if $breakpoint == desktop-medium {
    @media screen and (max-width: 1439px) {
      @content;
    }
  } @else if $breakpoint == desktop-large {
    @media screen and (max-width: 1919px) {
      @content;
    }
  }
}

/* positions */

@mixin position-absolute-full-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* size */

@mixin size($size) {
  min-width: $size;
  max-width: $size;
  min-height: $size;
  max-height: $size;
}

/* placeholder */

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

/* buttons */

@mixin button-reset {
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: default;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-transform: none;
  color: inherit;
  border: none;
  outline: none;
  background: none;
  text-shadow: none;
  font: inherit;
  line-height: inherit;

  &:focus {
    outline: none;
  }
}

/* links */

@mixin link-reset {
  all: unset;
}

/* scrollbar */

@mixin hide-scrollbar {
  overflow-y: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

/* progress-bar */

@mixin progress-bar-inner($color-bar, $color-value, $border-radius) {
  &::-moz-progress-bar {
    border-radius: $border-radius;
    background: $color-value;
  }

  &::-webkit-progress-value {
    border-radius: $border-radius;
    background: $color-value;
  }

  &::-webkit-progress-bar {
    border-radius: $border-radius;
    background: $color-bar;
  }
}

@mixin focus-visible {
  /* stylelint-disable-next-line */
  &:global(.focus-visible) {
    @content;
  }
}

@mixin line-clamp($n) {
  display: -webkit-box;
  -webkit-line-clamp: $n;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
