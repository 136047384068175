@import 'src/styles/vars';

$modal-overlay-padding: $spacing-small-x;

.ModalOverlay {
  padding: $modal-overlay-padding;
}

.Modal {
  // Костыль, т.к. иных способов сделать резину не нашел
  max-width: calc(100vw - #{$modal-overlay-padding * 2});
}
