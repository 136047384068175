@import 'src/styles/vars';

.AsideLayout,
.AsideLayout__outletContainer {
  width: 100%;
  height: 100%;
}

@media (max-width: 1279px) {
  .AsideLayout {
    padding: 24px 16px 48px;
  }

  .AsideLayout__outletContainer:not(:first-child) {
    margin-top: $spacing-large-x;
  }

  .AsideLayout__outletContainer:not(:last-child) {
    margin-bottom: $spacing-large-x;
  }
}

@media (min-width: 1280px) {
  .AsideLayout {
    display: flex;
    justify-content: space-between;
  }

  .AsideLayout__outletContainer {
    padding: 48px 64px 64px 48px;
  }
}

.AsideLayout__sidebar {
  position: relative;
  display: flex;
  flex-shrink: 0;
}

.AsideLayout__sidebarDecor {
  flex-shrink: 0;
}

.AsideLayout__content {
  width: 412px;
  padding: $spacing-medium-x;
  background: $on-surface-light-gray;
}
