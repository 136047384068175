@import 'src/styles/vars';

.HomePage__container {
  position: relative;
  display: flex;
  width: 100%;
}

.HomePage__contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
