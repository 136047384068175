@import '../vars';
/* stylelint-disable selector-class-pattern */

.hse-Select__valueContainer {
  color: $text-primary-black;

  &:hover {
    border-color: $brand-1;
  }
}

.hse-Select__valueContainer_focused {
  border-color: $brand-1;
}

.hse-Select__value_multiple {
  background-color: $brand-1;
}

.hse-Select__valuesCounter {
  background-color: $brand-1;
}

.hse-Select__option_selected {
  background-color: $brand-1;
}

.hse-DropdownMenuBaseItem_focused {
  box-shadow: 0 0 0 2px $brand-5;
}

.hse-DropdownMenuBaseItem_selected {
  background-color: $brand-1 !important;
}

.hse-DropdownMenuBaseItem_focused.hse-DropdownMenuBaseItem_selected {
  background-color: $brand-1;
}

.hse-Select__valueContainerIcon {
  color: $icon-secondary;
}
