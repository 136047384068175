@import 'src/styles/vars';

.Tab {
  @include text_caption_medium_bold;
  padding: $spacing-small-2x;
  color: $text-secondary;
  background-color: $on-surface-light-brand;
  border: none;
  cursor: pointer;
}

.Tab_active {
  color: white;
  background-color: $on-surface-brand;
  border: none;
}

.Tab:first-child {
  border-start-start-radius: $radius-medium;
  border-end-start-radius: $radius-medium;
}

.Tab:last-child {
  border-start-end-radius: $radius-medium;
  border-end-end-radius: $radius-medium;
}
