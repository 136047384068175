@import 'src/styles/vars';

.Avatar__container {
  display: flex;
  padding: $spacing-small-3x;
}

.Avatar__img {
  object-fit: cover;

  &_round {
    border-radius: 50%;
  }
}
