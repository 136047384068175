@import 'src/styles/vars';

.Activities {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-large $spacing-medium-x;

  @include media-down(tablet-large) {
    flex-direction: column;
    gap: $spacing-medium-x;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.Activities_variant_aside {
  flex-direction: column;
  gap: $spacing-small;
}
