@import 'src/styles/vars';

.QuizQuestionLayout__title {
  @include text_caption_x_large_bold;
  margin-bottom: $spacing-small;

  @include media-down(tablet-large) {
    @include text_caption_large_bold;
  }
}

.QuizQuestionLayout__title strong {
  font-size: $font-size-24;
}

.QuizQuestionLayout__answers {
  margin-bottom: $spacing-medium-x;
}

.QuizQuestionLayout__answer {
  &:not(:last-child) {
    margin-bottom: $spacing-small-x;
  }
}

.QuizQuestionLayout__answer_row {
  display: flex;
  align-items: center;
  gap: $spacing-small-3x;
}

.QuizQuestionLayout__heading {
  @include text_caption_x_large_bold;
  margin-bottom: $spacing-small-3x !important;
}
