@import './vars';

// Пере-записываем глобальные стили @hse-design
@import './hse-design/Button';
@import './hse-design/IconButton';
@import './hse-design/Checkbox';
@import './hse-design/Select';
@import './hse-design/Input';

html {
  font-family: $font-family-default;
  font-size: $font-size-16;
  color: $color-base-black;
  background-color: $color-background-white;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

address {
  font-style: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}
