@import 'src/styles/vars';

.SelectQuestion__answers {
  display: grid;
  grid-template-columns: minmax(max-content, 96px) auto;
  align-items: center;
  grid-gap: $spacing-small-x $spacing-small-3x;

  @include media-down(tablet-large) {
    grid-template-columns: auto;
    grid-gap: $spacing-small-3x;
  }
}

.SelectQuestion__select {
  @include media-down(tablet-large) {
    &:not(:last-child) {
      margin-bottom: $spacing-small-3x;
    }
  }
}
