@import 'src/styles/vars';

$progress-bar-height-s: 4px;

.DisciplineCard {
  padding: $spacing-small-x;
  border-radius: $radius-large;
  background-color: $on-surface-light-brand;
  display: grid;
  grid-template:
    'cover' 1fr
    'teacher' auto
    'progress' auto
    'bottom' auto;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0 4px 24px rgb(106 122 152 / 15%);
  }

  @include focus-visible {
    box-shadow: 0 4px 24px rgb(106 122 152 / 15%);
  }
}

.DisciplineCard__cover {
  border-radius: $radius-medium;
  background: $accent-gradient-diagonal;
  padding: $spacing-small $spacing-small-x;
  margin-bottom: $spacing-small;
  min-height: 96px;
  height: auto;
  grid-area: cover;
}

.DisciplineCard__coverText {
  @include text_caption_large_bold;
  color: $text-primary-white;
}

.DisciplineCard__teacher {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: $spacing-small-3x;
  grid-area: teacher;
}

.DisciplineCard__teacherName {
  @include text_caption_x_small_regular;
  color: $text-secondary;
  flex: 1;
}

.DisciplineCard__teacherImage {
  width: $sizing-24;
  height: $sizing-24;
  border-radius: 50%;
}

.DisciplineCard__progress {
  @include progress-bar-inner($on-surface-white, $on-surface-brand, $progress-bar-height-s);
  display: flex;
  overflow: hidden;
  width: 100%;
  height: $progress-bar-height-s;
  border-width: 0;
  border-color: transparent;
  border-radius: $progress-bar-height-s;
  background: $on-surface-white;
  grid-area: progress;
  margin-bottom: $spacing-small-x;
}

.DisciplineCard__bottom {
  flex: 1;
  align-content: end;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  grid-area: bottom;
}

.DisciplineCard__percent {
  @include text_caption_small_bold;
  color: $text-tertiary;
}

.DisciplineCard__link {
  grid-column: 2 / span 1;
}
