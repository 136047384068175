@import 'src/styles/vars';

.Link {
  color: $text-secondary;
  border-bottom-color: $text-quaternary !important;

  &:hover {
    color: $brand-1 !important;
    border-bottom-color: transparent !important;
  }

  &:focus {
    color: $brand-1 !important;
    border-bottom-color: $brand-1 !important;
  }
}
