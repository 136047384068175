@import 'src/styles/vars';

.QuizTable {
  width: 100%;
  max-width: 960px;

  .GradesTable__subhead {
    background-color: transparent;
  }

  .GradesTable__result {
    background-color: $on-surface-light-brand;

    @include media-down(tablet-small) {
      background-color: transparent;

      td {
        width: 50%;

        &:first-child {
          @include text_caption_small_regular;
          color: $text-secondary;
          padding-top: 4px;
        }
      }
    }
  }

  td:first-child {
    width: 48px;
  }
}

td.QuizTable__after {
  padding-top: $spacing-large;
}

.QuizTable__spinner {
  display: block;
  margin: 0 auto;
}

.QuizTable__loadMore {
  margin: 0 auto;
}
