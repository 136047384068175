@import 'src/styles/vars';

.LessonPage__container {
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-down(desktop-medium) {
    justify-content: space-between;
  }
}

.LessonPage__container_centered {
  align-items: center;
  justify-content: center;
  height: calc(100vh - $header-height - $spacing-large * 2);
}

.LessonPage__content {
  max-width: 656px;
  min-width: 0;
  flex-grow: 1;

  @include media-up(desktop-large) {
    max-width: 800px;
  }
}

.LessonPage__back {
  margin-bottom: $spacing-small;
}

.LessonPage__header,
.LessonPage__literature {
  margin-bottom: $spacing-small;

  @include media-down(tablet-large) {
    margin-bottom: $spacing-small-x;
  }
}

.LessonPage__bc {
  margin-bottom: $spacing-small;
}

.LessonPage__lessonContent {
  margin-bottom: $spacing-small-x;

  iframe {
    display: block;
    max-width: 100%;
  }
}

.LessonPage__controls {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: $spacing-medium;
  }
}

.LessonPage__controlsButton_right {
  margin-left: auto;
}

.LessonPage__buttons {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-2x;
}
